import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';

const BlogList = () => {
  const { theme } = useTheme();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('/data/blog-posts.json');
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className={`min-h-screen ${
      theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'
    }`}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">Blog</h1>
        
        {loading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <div className="grid gap-8">
            {posts.map(post => (
              <article 
                key={post.slug}
                className={`${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-white'
                } rounded-lg shadow-lg overflow-hidden`}
              >
                <div className="flex flex-col md:flex-row">
                  {post.coverImage && (
                    <div className="md:w-1/3">
                      <img 
                        src={post.coverImage} 
                        alt={post.title}
                        className="w-full h-48 md:h-full object-cover"
                        onError={(e) => {
                          console.error('Image load error:', post.coverImage);
                          e.target.style.display = 'none';
                        }}
                      />
                    </div>
                  )}
                  <div className="p-6 md:w-2/3">
                    <h2 className="text-2xl font-bold mb-2">
                      <Link 
                        to={`/blog/${post.slug}`}
                        className="hover:text-blue-500 transition-colors"
                      >
                        {post.title}
                      </Link>
                    </h2>
                    <div className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                      <time>{new Date(post.date).toLocaleDateString()}</time>
                      <span className="mx-2">·</span>
                      <span>{post.readingTime} min read</span>
                    </div>
                    <p className="text-gray-600 dark:text-gray-300 mb-4">
                      {post.excerpt}
                    </p>
                    <Link 
                      to={`/blog/${post.slug}`}
                      className="text-blue-500 hover:text-blue-600 transition-colors"
                    >
                      Read more →
                    </Link>
                  </div>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogList; 