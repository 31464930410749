// 从 blog-posts.json 读取最新文章
async function getLatestPosts() {
  try {
    const response = await fetch('/data/blog-posts.json');
    const posts = await response.json();
    return posts.slice(0, 3); // 只返回最新的3篇
  } catch (error) {
    console.error('Error loading blog posts:', error);
    // 返回默认内容作为后备
    return [
      {
        slug: 'memory-training-tips',
        title: '记忆训练小技巧',
        excerpt: '想要提高记忆力？这里有一些实用的训练方法和技巧...'
      },
      {
        slug: 'brain-exercise-guide',
        title: '大脑训练完全指南',
        excerpt: '通过记忆游戏训练大脑，提升认知能力...'
      },
      {
        slug: 'concentration-improvement',
        title: '如何提高专注力',
        excerpt: '玩记忆游戏不仅有趣，还能帮助提升专注力...'
      }
    ];
  }
}

export const benefits = [
  {
    icon: '🧠',
    title: 'benefitMemory',
    description: '通过重复练习增强短期和长期记忆能力'
  },
  {
    icon: '👁️',
    title: 'benefitFocus',
    description: '提升注意力集中度和持续时间'
  },
  {
    icon: '⚡',
    title: 'benefitSpeed',
    description: '加快反应速度和决策能力'
  },
  {
    icon: '🎮',
    title: 'benefitFun',
    description: '寓教于乐，让训练变得有趣'
  }
];

export { getLatestPosts }; 