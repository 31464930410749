import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const LeaderboardPanel = ({ rankings, className = '', compact = false }) => {
  const { t } = useLanguage();
  const playerName = localStorage.getItem('playerName');
  
  // 查找玩家在排行榜中的位置和成绩
  const playerSpeedRank = rankings.speed.findIndex(entry => entry.name === playerName) + 1;
  const playerScoreRank = rankings.score.findIndex(entry => entry.name === playerName) + 1;
  const playerBestSpeed = rankings.speed.find(entry => entry.name === playerName)?.time;
  const playerBestScore = rankings.score.find(entry => entry.name === playerName)?.score;

  // 紧凑版布局
  if (compact) {
    return (
      <div className={`${className} p-3`}>
        <h3 className="text-base font-bold mb-2">{t('leaderboard')}</h3>
        
        {/* 玩家最佳成绩 - 紧凑版 */}
        {playerName && (playerBestSpeed || playerBestScore) && (
          <div className="mb-3 p-2 bg-blue-50 rounded text-xs">
            {playerBestSpeed && (
              <div className="flex justify-between items-center">
                <span>{t('speedRanking')}</span>
                <span className="text-blue-600">{playerBestSpeed}s</span>
              </div>
            )}
            {playerBestScore && (
              <div className="flex justify-between items-center mt-1">
                <span>{t('scoreRanking')}</span>
                <span className="text-blue-600">{playerBestScore}</span>
              </div>
            )}
          </div>
        )}
        
        {/* 速度排行 - 紧凑版 */}
        <div className="mb-3">
          <h4 className="text-xs text-gray-600 mb-1">{t('speedRanking')}</h4>
          <div className="space-y-1">
            {rankings.speed.slice(0, 10).map((entry, index) => (
              <div 
                key={index} 
                className={`flex items-center text-xs ${entry.name === playerName ? 'bg-blue-50 -mx-1 px-1 rounded' : ''}`}
              >
                <span className="w-4 text-gray-400">{index + 1}</span>
                <span className="flex-1 truncate">{entry.name}</span>
                <span className="text-gray-600 font-mono ml-1">{entry.time}s</span>
              </div>
            ))}
          </div>
        </div>
        
        {/* 得分排行 - 紧凑版 */}
        <div className="mb-3">
          <h4 className="text-xs text-gray-600 mb-1">{t('scoreRanking')}</h4>
          <div className="space-y-1">
            {rankings.score.slice(0, 10).map((entry, index) => (
              <div 
                key={index} 
                className={`flex items-center text-xs ${entry.name === playerName ? 'bg-blue-50 -mx-1 px-1 rounded' : ''}`}
              >
                <span className="w-4 text-gray-400">{index + 1}</span>
                <span className="flex-1 truncate">{entry.name}</span>
                <span className="text-gray-600 font-mono ml-1">{entry.score}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="text-xs text-center text-gray-500 border-t pt-2">
          {t('totalPlayers')}: {rankings.totalPlayers.toLocaleString()}
        </div>
      </div>
    );
  }

  return (
    <div className={`${className} p-4`}>
      <h3 className="font-bold text-lg mb-3">{t('leaderboard')}</h3>
      
      {/* 玩家最佳成绩 */}
      {playerName && (
        <div className="mb-4 p-3 bg-blue-50 rounded-lg">
          <h4 className="font-semibold text-sm text-blue-700 mb-2">{t('yourBest')}</h4>
          <div className="space-y-1 text-sm">
            {playerBestSpeed && (
              <div className="flex justify-between">
                <span>{t('speedRanking')}</span>
                <span className="text-blue-600">
                  {playerBestSpeed}s (#{playerSpeedRank}/100)
                </span>
              </div>
            )}
            {playerBestScore && (
              <div className="flex justify-between">
                <span>{t('scoreRanking')}</span>
                <span className="text-blue-600">
                  {playerBestScore} (#{playerScoreRank}/100)
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      
      {/* 速度排行 */}
      <div className="mb-4">
        <h4 className="font-semibold text-sm text-gray-600 mb-2">{t('speedRanking')}</h4>
        <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
          {rankings.speed.slice(0, 10).map((entry, index) => (
            <div 
              key={index} 
              className={`flex items-center text-sm ${entry.name === playerName ? 'bg-blue-50 -mx-2 px-2 py-1 rounded' : ''}`}
            >
              <span className="w-6 text-gray-400">{index + 1}.</span>
              <span className="flex-1 truncate">{entry.name}</span>
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-500">Lv.{entry.level}</span>
                <span className="text-gray-600 font-mono">{entry.time}s</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* 得分排行 */}
      <div className="mb-4">
        <h4 className="font-semibold text-sm text-gray-600 mb-2">{t('scoreRanking')}</h4>
        <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
          {rankings.score.slice(0, 10).map((entry, index) => (
            <div 
              key={index} 
              className={`flex items-center text-sm ${entry.name === playerName ? 'bg-blue-50 -mx-2 px-2 py-1 rounded' : ''}`}
            >
              <span className="w-6 text-gray-400">{index + 1}.</span>
              <span className="flex-1 truncate">{entry.name}</span>
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-500">Lv.{entry.level}</span>
                <span className="text-gray-600 font-mono">{entry.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 总参与人数 */}
      <div className="text-center text-sm text-gray-500 border-t pt-3">
        {t('totalPlayers')}: {rankings.totalPlayers.toLocaleString()}
      </div>
    </div>
  );
};

export default LeaderboardPanel; 