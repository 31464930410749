import React from 'react';

const SocialShare = ({ score, level }) => {
  const shareUrl = 'https://cardmatchgame.com';
  const shareText = `I just completed level ${level} with ${score} points in Memory Card Game! Can you beat my score?`;
  
  const shareButtons = [
    {
      name: 'Facebook',
      icon: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`,
      color: '#1877F2'
    },
    {
      name: 'Twitter',
      icon: 'twitter',
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`,
      color: '#1DA1F2'
    },
    {
      name: 'WhatsApp',
      icon: 'whatsapp',
      url: `whatsapp://send?text=${encodeURIComponent(`${shareText} ${shareUrl}`)}`,
      color: '#25D366'
    },
    {
      name: 'Telegram',
      icon: 'telegram',
      url: `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`,
      color: '#0088cc'
    },
    {
      name: 'LINE',
      icon: 'line',
      url: `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(shareUrl)}`,
      color: '#00B900'
    }
  ];

  const handleShare = (url, name) => {
    window.open(url, `Share on ${name}`, 'width=600,height=400');
  };

  return (
    <div className="fixed left-4 top-1/2 -translate-y-1/2 flex flex-col gap-3 z-50">
      {shareButtons.map((button) => (
        <button
          key={button.name}
          onClick={() => handleShare(button.url, button.name)}
          className="w-12 h-12 rounded-full flex items-center justify-center text-white shadow-lg hover:scale-110 transition-transform"
          style={{ backgroundColor: button.color }}
          aria-label={`Share on ${button.name}`}
          title={button.name}
        >
          <i className={`fab fa-${button.icon} text-xl`}></i>
        </button>
      ))}
    </div>
  );
};

export default SocialShare; 