export const translations = {
  en: {
    title: "Memory Card Game",
    level: "Level {level}",
    timeUsed: "Time Used",
    tool1: "Peek Again",
    tool2: "Auto Match",
    tool3: "Shuffle",
    restart: "Restart",
    matched: "Matched",
    score: "Score",
    combo: "Combo",
    nextLevel: "Next Level",
    congratulations: "Congratulations! Total Score:",
    points: "points",
    matchedCount: "Matched: {count} / {total}",
    scoreLabel: "Score: {score}",
    comboPoints: "+{points} points! {combo} Combo!",
    leaderboard: "Leaderboard",
    speedRanking: "Speed Ranking",
    scoreRanking: "Score Ranking",
    totalPlayers: "Total Players",
    yourName: "Your Name",
    edit: "Edit",
    save: "Save",
    yourBest: "Your Best Records",
  },
  zh: {
    title: "记忆卡牌游戏",
    level: "第{level}关",
    timeUsed: "已用时",
    tool1: "再看一次",
    tool2: "随机消除",
    tool3: "刷新位置",
    restart: "重新开始",
    matched: "已匹配",
    score: "得分",
    combo: "连击",
    nextLevel: "下一关",
    congratulations: "恭喜通关！总分：",
    points: "分",
    matchedCount: "已匹配: {count} / {total}",
    scoreLabel: "得分: {score}",
    comboPoints: "+{points} 分！{combo} 连击！",
    leaderboard: "排行榜",
    speedRanking: "速度排名",
    scoreRanking: "得分排名",
    totalPlayers: "总参与人数",
    yourName: "你的名字",
    edit: "编辑",
    save: "保存",
    yourBest: "你的最佳记录",
  },
  ja: {
    title: "メモリーカードゲーム",
    level: "レベル{level}",
    timeUsed: "経過時間",
    tool1: "もう一度見る",
    tool2: "自動マッチ",
    tool3: "シャッフル",
    restart: "リスタート",
    matched: "マッチ済み",
    score: "スコア",
    combo: "コンボ",
    nextLevel: "次のレベル",
    congratulations: "おめでとう！合計スコア：",
    points: "点",
    matchedCount: "マッチ: {count} / {total}",
    scoreLabel: "スコア: {score}",
    comboPoints: "+{points}点！{combo}コンボ！",
    leaderboard: "ランキング",
    speedRanking: "スピードランキング",
    scoreRanking: "スコアランキング",
    totalPlayers: "総プレイヤー数",
    yourName: "プレイヤー名",
    edit: "編集",
    save: "保存",
    yourBest: "あなたの最高記録",
  },
  es: {
    title: "Juego de Memoria",
    level: "Nivel {level}",
    timeUsed: "Tiempo usado",
    tool1: "Mirar de nuevo",
    tool2: "Auto emparejar",
    tool3: "Mezclar",
    restart: "Reiniciar",
    matched: "Emparejados",
    score: "Puntuación",
    combo: "Combo",
    nextLevel: "Siguiente nivel",
    congratulations: "¡Felicitaciones! Puntuación total:",
    points: "puntos",
    matchedCount: "Emparejados: {count} / {total}",
    scoreLabel: "Puntuación: {score}",
    comboPoints: "¡+{points} puntos! ¡{combo} combo!",
    leaderboard: "Tabla de clasificación",
    speedRanking: "Ranking de velocidad",
    scoreRanking: "Ranking de puntuación",
    totalPlayers: "Jugadores totales",
    yourName: "Tu nombre",
    edit: "Editar",
    save: "Guardar",
    yourBest: "Tus mejores registros",
  }
}; 