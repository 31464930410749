import React, { useState, useEffect, useCallback } from 'react';
import { useLanguage } from './contexts/LanguageContext';
import { useTheme } from './contexts/ThemeContext';
import { Link } from 'react-router-dom';
import LeaderboardPanel from './components/LeaderboardPanel';
import SocialShare from './components/SocialShare';
import { trackGameEvent } from './utils/analytics';
import { recentPosts, benefits, getLatestPosts } from './data/content';

// 修改关卡配置，只保留 4x4, 6x6, 8x8, 10x10
const LEVEL_CONFIGS = [
  { gridSize: 4 },  // Level 1: 4x4 (8对)
  { gridSize: 6 },  // Level 2: 6x6 (18对)
  { gridSize: 8 },  // Level 3: 8x8 (32对)
  { gridSize: 10 }, // Level 4: 10x10 (50对)
];

const INITIAL_GRID_SIZE = LEVEL_CONFIGS[0].gridSize;
const COUNTDOWN_TIME = 6;
const PEEK_TIME = 5000;

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const generateCards = (gridSize) => {
  const totalCards = gridSize * gridSize;
  // 精选15个容易区分的表情
  const emojis = [
    '🐶', '🐱', '🐼', '🐨', '🦊',  // 常见可爱动物
    '🐸', '🐯', '🦁', '🐮', '🐷',  // 特征明显的动物
    '🦉', '🦒', '🐘', '🦈', '🐙'   // 独特外形动物
  ];

  let allCards = [];
  const pairsNeeded = totalCards / 2;
  
  // 确保不会超出表情列表范围
  // if (pairsNeeded > emojis.length) {
    // console.warn(`需要 ${pairsNeeded} 对表情，但只有 ${emojis.length} 个可用`);
  // }

  // 循环使用表情直到达到所需数量
  for (let i = 0; i < pairsNeeded; i++) {
    const emoji = emojis[i % emojis.length];
    allCards.push(emoji, emoji); // 确保每个表情都是成对添加
  }

  return shuffleArray(allCards).map((emoji, index) => ({
    id: index,
    emoji,
    flipped: true,
    matched: false
  }));
};

const calculateEmojiSize = (gridSize) => {
  // 根据格子数量调整基础大小
  const sizes = {
    4: { base: 3.5, mobile: 2.8 },
    6: { base: 2.5, mobile: 2.0 },
    8: { base: 2.0, mobile: 1.6 },
    10: { base: 1.5, mobile: 1.2 }
  };
  
  const size = sizes[gridSize] || sizes[10]; // 默认使用最小尺寸

  return {
    mobile: `${size.mobile}rem`,
    desktop: `${size.base}rem`
  };
};

const MemoryGame = () => {
  const { t } = useLanguage();
  const { theme } = useTheme();
  
  // 先定义所有状态
  const [gridSize, setGridSize] = useState(INITIAL_GRID_SIZE);
  const [level, setLevel] = useState(1);
  const [cards, setCards] = useState(generateCards(gridSize));
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState(0);
  const [isChecking, setIsChecking] = useState(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_TIME);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [combo, setCombo] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showNextLevel, setShowNextLevel] = useState(false);
  const [tool1Uses, setTool1Uses] = useState(level);
  const [tool2Uses, setTool2Uses] = useState(level);
  const [tool3Uses, setTool3Uses] = useState(level);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [rankings, setRankings] = useState({
    speed: [],
    score: [],
    totalPlayers: 0
  });

  // 然后定义事件追踪
  const trackGame = useCallback((event, params = {}) => {
    trackGameEvent(event, {
      ...params,
      level,
      grid_size: gridSize
    });
  }, [level, gridSize]);

  const [playerName] = useState(() => {
    const savedName = localStorage.getItem('playerName');
    if (!savedName) {
      const newName = `user-${Math.floor(Math.random() * 10000)}`;
      localStorage.setItem('playerName', newName);
      return newName;
    }
    return savedName;
  });

  const startTimer = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const id = setInterval(() => {
      setElapsedTime(prevTime => prevTime + 1);
    }, 1000);
    setIntervalId(id);
  }, [intervalId]);

  const stopTimer = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId]);

  const fetchRankings = useCallback(async () => {
    try {
      if (process.env.NODE_ENV === 'production') {
        const response = await fetch('/api/leaderboard', {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        if (!data.speedRanking || !data.scoreRanking) {
          return;
        }

        setRankings({
          speed: data.speedRanking || [],
          score: data.scoreRanking || [],
          totalPlayers: data.totalPlayers || 0
        });
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.debug('Fetch rankings error:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && !gameStarted) {
      setCards(cards.map(card => ({ ...card, flipped: false })));
      setGameStarted(true);
      startTimer();
      fetchRankings();
      trackGame('game_start', {
        initial_level: level
      });
    }
  }, [countdown, gameStarted, cards, startTimer, level, fetchRankings, trackGame]);

  useEffect(() => {
    if (flippedCards.length === 2) {
      setIsChecking(true);
      const [first, second] = flippedCards;

      const checkTimeout = setTimeout(() => {
        if (cards[first].emoji === cards[second].emoji) {
          const newCombo = combo + 1;
          const points = newCombo * 10;
          
          setScore(prevScore => prevScore + points);
          setCombo(newCombo);
          setAlertMessage(t('comboPoints')
            .replace('{points}', points)
            .replace('{combo}', newCombo)
          );
          setShowAlert(true);
          
          setCards(prevCards => prevCards.map((card, index) => 
            index === first || index === second ? { ...card, matched: true, flipped: false } : card
          ));
          setMatchedPairs(prev => prev + 1);
          
          setTimeout(() => setShowAlert(false), 800);
        } else {
          setCards(prevCards => prevCards.map((card, index) => 
            index === first || index === second ? { ...card, flipped: false } : card
          ));
          setCombo(0);
        }

        setFlippedCards([]);
        setIsChecking(false);
      }, 400);

      return () => clearTimeout(checkTimeout);
    }
  }, [flippedCards, cards, combo, t]);

  useEffect(() => {
    if (matchedPairs === (gridSize * gridSize) / 2) {
      setShowNextLevel(true);
      stopTimer();
      
      // 只在生产环境提交分数
      if (process.env.NODE_ENV === 'production') {
        fetch('/api/leaderboard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: playerName,
            level,
            time: elapsedTime,
            score
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data && data.speedRank) {
            setRankings(prevRankings => {
              const newSpeedRanking = [...prevRankings.speed];
              const newScoreRanking = [...prevRankings.score];
              
              const playerSpeedEntry = { name: playerName, level, time: elapsedTime };
              newSpeedRanking.splice(data.speedRank - 1, 0, playerSpeedEntry);
              newSpeedRanking.length = Math.min(newSpeedRanking.length, 5);
              
              if (data.scoreRank) {
                const playerScoreEntry = { name: playerName, level, score };
                newScoreRanking.splice(data.scoreRank - 1, 0, playerScoreEntry);
                newScoreRanking.length = Math.min(newScoreRanking.length, 5);
              }
              
              const newRankings = {
                ...prevRankings,
                speed: newSpeedRanking,
                score: newScoreRanking,
                totalPlayers: (prevRankings.totalPlayers || 0) + 1
              };
              
              console.log('Rankings update:', {
                previous: prevRankings,
                new: newRankings
              });
              
              return newRankings;
            });
            fetchRankings();
          }
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'development') {
            console.debug('Submit score error:', error);
          }
        });
      }
    }
  }, [matchedPairs, gridSize, stopTimer, level, elapsedTime, score, playerName, fetchRankings]);

  const handleCardClick = useCallback((index) => {
    if (
      !gameStarted || 
      isChecking || 
      flippedCards.length >= 2 || 
      cards[index].flipped || 
      cards[index].matched
    ) {
      return;
    }

    setCards(prevCards => prevCards.map((card, i) => 
      i === index ? { ...card, flipped: true } : card
    ));
    setFlippedCards(prev => [...prev, index]);
  }, [gameStarted, isChecking, flippedCards.length, cards]);

  const handleNextLevel = () => {
    stopTimer();
    
    // 检查是否还有下一关
    if (level < LEVEL_CONFIGS.length) {
      const newLevel = level + 1;
      const newGridSize = LEVEL_CONFIGS[newLevel - 1].gridSize;
      
      setLevel(newLevel);
      setGridSize(newGridSize);
      setCards(generateCards(newGridSize));
      setFlippedCards([]);
      setMatchedPairs(0);
      setCountdown(COUNTDOWN_TIME);
      setGameStarted(false);
      setShowNextLevel(false);
      setScore(0);
      setCombo(0);
      setTool1Uses(newLevel);
      setTool2Uses(newLevel);
      setTool3Uses(newLevel);
      setElapsedTime(0);
    } else {
      // 通关后的处理
      setShowNextLevel(false);
      // 可以显示通关祝贺或其他内容
    }
  };

  const handleRestart = () => {
    trackGame('game_restart');
    stopTimer();
    setCards(generateCards(gridSize));
    setFlippedCards([]);
    setMatchedPairs(0);
    setCountdown(COUNTDOWN_TIME);
    setGameStarted(false);
    setScore(0);
    setCombo(0);
    setTool1Uses(level);
    setTool2Uses(level);
    setTool3Uses(level);
    setElapsedTime(0);
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const useTool1 = () => {
    if (tool1Uses > 0 && gameStarted && !isChecking) {
      trackGame('use_tool', {
        tool_name: 'peek_again',
        uses_left: tool1Uses - 1
      });
      setTool1Uses(tool1Uses - 1);
      setIsChecking(true);

      setCards(prevCards => prevCards.map(card => ({ ...card, flipped: true })));

      setTimeout(() => {
        setCards(prevCards => 
          prevCards.map(card => 
            card.matched ? card : { ...card, flipped: false }
          )
        );
        setIsChecking(false);
      }, PEEK_TIME);
    }
  };

  const useTool2 = () => {
    if (tool2Uses > 0 && gameStarted && !isChecking) {
      setTool2Uses(tool2Uses - 1);
      let pairsRemoved = 0;
      setCards(prevCards => {
        const newCards = [...prevCards];
        for (let i = 0; i < newCards.length; i++) {
          if (!newCards[i].matched && pairsRemoved < 2) {
            const matchIndex = newCards.findIndex((card, index) => index !== i && card.emoji === newCards[i].emoji && !card.matched);
            if (matchIndex !== -1) {
              newCards[i].matched = true;
              newCards[matchIndex].matched = true;
              pairsRemoved += 1;
            }
          }
        }
        setMatchedPairs(matchedPairs + pairsRemoved);
        return newCards;
      });
    }
  };

  const useTool3 = () => {
    if (tool3Uses > 0 && gameStarted && !isChecking) {
      setTool3Uses(tool3Uses - 1);
      setCards(prevCards => {
        const newCards = prevCards.filter(card => !card.matched);
        return shuffleArray(newCards).concat(prevCards.filter(card => card.matched));
      });
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  // 计算当前表情大小
  const emojiSize = calculateEmojiSize(gridSize);

  // 添加错误跟踪
  window.addEventListener('error', (event) => {
    if (window.gtag) {
      window.gtag('event', 'error', {
        error_message: event.message,
        error_stack: event.error?.stack,
        page_url: window.location.href
      });
    }
  });

  // 渲染部分
  return (
    <div className={`min-h-screen ${
      theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-game-light text-gray-900'
    }`}>
      <div className="container mx-auto px-4 py-8 relative">
        <div className="hidden md:block">
          <SocialShare score={score} level={level} />
        </div>

        {/* 游戏主体部分和排行榜的布局 */}
        <div className="flex flex-col lg:flex-row justify-center lg:space-x-8">
          <div className="w-full max-w-[500px] mx-auto">
            <div className={`game-container p-6 rounded-xl mb-16 ${
              theme === 'dark' ? 'bg-gray-800' : 'bg-white'
            }`}>
              {/* 游戏状态显示 */}
              <div className="grid grid-cols-4 gap-4 mb-4 text-center">
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600 dark:text-gray-400 mb-1">{t('level')}</span>
                  <span className="text-lg font-bold text-red-500">{level}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600 dark:text-gray-400 mb-1">{t('timeUsed')}</span>
                  <span className="text-base font-mono font-bold text-red-500 text-center">{formatTime(elapsedTime)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600 dark:text-gray-400 mb-1">{t('matched')}</span>
                  <span className="text-base font-bold text-red-500 text-center">
                    {matchedPairs}/{gridSize * gridSize / 2}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-xs text-gray-600 dark:text-gray-400 mb-1">{t('score')}</span>
                  <span className="text-lg font-bold text-red-500">{score}</span>
                </div>
              </div>

              {/* 卡片网格 */}
              <div className="w-full mb-4">
                <div
                  className="grid bg-white rounded-lg shadow-lg w-full p-2 sm:p-4"
                  style={{ 
                    gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
                    gap: '0.35rem'
                  }}
                >
                  {cards.map((card, index) => (
                    <button
                      key={card.id}
                      className={`aspect-square flex items-center justify-center rounded-md transition-all duration-300
                                 ${card.matched ? 'invisible' : card.flipped ? 'bg-blue-500 text-white' : 'bg-gray-300'}
                                 hover:bg-blue-400`}
                      onClick={() => handleCardClick(index)}
                      disabled={!gameStarted || card.flipped || card.matched || isChecking}
                    >
                      {card.flipped && (
                        <span 
                          className="transform scale-[0.8] leading-none"
                          style={{
                            fontSize: window.innerWidth < 640 ? emojiSize.mobile : emojiSize.desktop
                          }}
                        >
                          {card.emoji}
                        </span>
                      )}
                    </button>
                  ))}
                </div>
              </div>

              {/* 工具按钮 */}
              <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-2">
                <button onClick={useTool1} className="px-3 py-2 bg-blue-500 text-white rounded-lg text-sm sm:text-base" disabled={tool1Uses <= 0 || isChecking}>
                  {t('tool1')} ({tool1Uses})
                </button>
                <button onClick={useTool2} className="px-3 py-2 bg-blue-500 text-white rounded-lg text-sm sm:text-base" disabled={tool2Uses <= 0 || isChecking}>
                  {t('tool2')} ({tool2Uses})
                </button>
                <button onClick={useTool3} className="px-3 py-2 bg-blue-500 text-white rounded-lg text-sm sm:text-base" disabled={tool3Uses <= 0 || isChecking}>
                  {t('tool3')} ({tool3Uses})
                </button>
                {countdown > 0 ? (
                  <div className="flex items-center justify-center px-3 py-2 bg-blue-500 text-white rounded-lg text-xl font-bold">
                    {countdown}
                  </div>
                ) : (
                  <button onClick={handleRestart} className="px-3 py-2 bg-red-500 text-white rounded-lg text-sm sm:text-base">
                    {t('restart')}
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* 排行榜 */}
          <div className="hidden lg:block w-[280px] flex-shrink-0">
            <div className="sticky top-4">
              <LeaderboardPanel 
                rankings={rankings}
                className={`rounded-lg shadow-lg ${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-white'
                }`}
                compact={window.innerWidth < 1280}
              />
            </div>
          </div>
        </div>

        {/* 移动端排行榜 */}
        <div className="lg:hidden mt-8 max-w-[500px] mx-auto w-full">
          <LeaderboardPanel 
            rankings={rankings}
            className={`rounded-lg shadow-lg ${
              theme === 'dark' ? 'bg-gray-800' : 'bg-white'
            }`}
            compact={true}
          />
        </div>

        {/* 提示消息 */}
        {showAlert && (
          <div className="fixed top-16 left-1/2 transform -translate-x-1/2 bg-green-100 border-green-400 text-green-700 p-2 sm:p-4 rounded-md shadow-lg text-sm sm:text-base z-50">
            <div className="text-center">{alertMessage}</div>
          </div>
        )}

        {/* 下一关提示 */}
        {showNextLevel && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 p-4 z-50">
            <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-center w-full max-w-sm">
              <h2 className="text-xl sm:text-2xl font-bold mb-4">
                {t('congratulations')} {score} {t('points')}
              </h2>
              <button
                onClick={handleNextLevel}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                {t('nextLevel')}
              </button>
            </div>
          </div>
        )}

        {/* 游戏说明和其他内容 */}
        <GameInstructions t={t} theme={theme} />
        <BlogPreview t={t} theme={theme} />
        <GameBenefits t={t} benefits={benefits} theme={theme} />
      </div>
    </div>
  );
};

// 拆分成独立组件
const GameInstructions = ({ t, theme }) => (
  <div className="max-w-6xl mx-auto mb-16">
    <h2 className={`text-3xl font-bold text-center mb-10 ${
      theme === 'dark' ? 'text-white' : 'text-gray-900'
    }`}>
      {t('howToPlay')}
    </h2>
    <div className="grid md:grid-cols-3 gap-8">
      <div className={`rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow ${
        theme === 'dark' ? 'bg-gray-800' : 'bg-white'
      }`}>
        <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4 mx-auto">
          <span className="text-2xl">👀</span>
        </div>
        <h3 className={`text-xl font-semibold text-center mb-3 ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>{t('step1Title')}</h3>
        <p className={`text-center ${
          theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
        }`}>{t('step1Description')}</p>
      </div>
      
      <div className={`rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow ${
        theme === 'dark' ? 'bg-gray-800' : 'bg-white'
      }`}>
        <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4 mx-auto">
          <span className="text-2xl">🤔</span>
        </div>
        <h3 className={`text-xl font-semibold text-center mb-3 ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>{t('step2Title')}</h3>
        <p className={`text-center ${
          theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
        }`}>{t('step2Description')}</p>
      </div>
      
      <div className={`rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow ${
        theme === 'dark' ? 'bg-gray-800' : 'bg-white'
      }`}>
        <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mb-4 mx-auto">
          <span className="text-2xl">🎯</span>
        </div>
        <h3 className={`text-xl font-semibold text-center mb-3 ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>{t('step3Title')}</h3>
        <p className={`text-center ${
          theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
        }`}>{t('step3Description')}</p>
      </div>
    </div>
  </div>
);

const BlogPreview = ({ t, theme }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      const latestPosts = await getLatestPosts();
      setPosts(latestPosts);
    };
    loadPosts();
  }, []);

  return (
    <div className="max-w-6xl mx-auto mb-16">
      <div className="flex justify-between items-center mb-8">
        <h2 className={`text-3xl font-bold ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>{t('latestPosts')}</h2>
        <Link to="/blog" className="text-blue-500 hover:text-blue-700">
          {t('viewAll')} →
        </Link>
      </div>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {posts.map(post => (
          <div key={post.slug} className={`${
            theme === 'dark' ? 'bg-gray-800' : 'bg-white'
          } rounded-xl shadow-lg hover:shadow-xl transition-shadow overflow-hidden`}>
            {post.coverImage && (
              <img 
                src={post.coverImage}
                alt={post.title}
                className="w-full h-48 object-cover"
                onError={(e) => {
                  console.error('Image load error:', post.coverImage);
                  e.target.style.display = 'none';
                }}
              />
            )}
            <div className="p-6">
              <h3 className={`text-xl font-semibold mb-3 line-clamp-2 ${
                theme === 'dark' ? 'text-white' : 'text-gray-900'
              }`}>
                {post.title}
              </h3>
              <p className={`mb-4 line-clamp-3 ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
              }`}>
                {post.excerpt}
              </p>
              <Link 
                to={`/blog/${post.slug}`}
                className="text-blue-500 hover:text-blue-700"
              >
                {t('readMore')} →
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const GameBenefits = ({ t, benefits, theme }) => (
  <div className="max-w-6xl mx-auto mb-16">
    <h2 className="text-3xl font-bold text-center mb-10">{t('benefits')}</h2>
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
      {benefits.map((benefit, index) => (
        <div key={index} className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow">
          <div className="w-12 h-12 bg-yellow-100 rounded-full flex items-center justify-center mb-4 mx-auto">
            <span className="text-xl">{benefit.icon}</span>
          </div>
          <h3 className="text-lg font-semibold text-center mb-2">
            {t(benefit.title)}
          </h3>
          <p className="text-gray-600 text-center text-sm">{benefit.description}</p>
        </div>
      ))}
    </div>
  </div>
);

export default MemoryGame;
