import React, { createContext, useState, useEffect, useContext } from 'react';
import { translations } from '../i18n/translations';

export const LanguageContext = createContext();

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return {
    ...context,
    t: (key) => translations[context.language][key] || key,
  };
};

export const LanguageProvider = ({ children }) => {
  // 获取系统语言
  const getSystemLanguage = () => {
    const browserLang = navigator.language.toLowerCase().split('-')[0];
    return ['en', 'zh', 'ja', 'es'].includes(browserLang) ? browserLang : 'en';
  };

  // 从 localStorage 或系统设置获取初始语言
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const savedLang = localStorage.getItem('language');
    return savedLang || getSystemLanguage();
  });

  const setLanguage = (lang) => {
    setCurrentLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    // 监听系统语言变化
    const handleLanguageChange = () => {
      if (localStorage.getItem('language') === 'auto') {
        setCurrentLanguage(getSystemLanguage());
      }
    };

    window.addEventListener('languagechange', handleLanguageChange);
    return () => window.removeEventListener('languagechange', handleLanguageChange);
  }, []);

  useEffect(() => {
    // 更新HTML lang属性
    document.documentElement.lang = currentLanguage;
    
    // 更新标题
    document.title = currentLanguage === 'zh' 
      ? "记忆卡牌游戏 - 免费在线记忆力训练游戏" 
      : "Memory Card Game - Free Online Memory Games";
  }, [currentLanguage]);

  return (
    <LanguageContext.Provider value={{ 
      language: currentLanguage, 
      setLanguage,
      currentLanguage, // 添加这个以便在组件中访问
      getSystemLanguage // 添加这个以便在组件中访问
    }}>
      {children}
    </LanguageContext.Provider>
  );
}; 