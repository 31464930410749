export const trackGameEvent = (eventName, eventParams = {}) => {
  try {
    if (window.gtag) {
      window.gtag('event', eventName, eventParams);
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.debug('Analytics tracking error:', error);
    }
  }
}; 